import React, { Component } from "react";
import { Navbar, Nav, Container, Row, Col, NavDropdown } from "react-bootstrap";
import logo from '../../imgs/logo.jpeg'
export default class Header extends Component {
  constructor() {
    super();
    // this.scrollAbout = this.scrollAbout.bind(this);
  }

  // scrollAbout = (e) => {
  //   e.preventDefault();
  //   this.props.handleScrollAbout();
  // };

  // scrollMission = (e) => {
  //   e.preventDefault();
  //   this.props.handleScrollMission();
  // };

  // scrollChoose = (e) => {
  //   e.preventDefault();
  //   this.props.handleScrollChoose();
  // };
  // /* Open when someone clicks on the span element */
  // openOverlay = () => {
  //   document.getElementById("myNav").style.width = "100%";
  // };

  // /* Close when someone clicks on the "x" symbol inside the overlay */
  // closeNav = () => {
  //   document.getElementById("myNav").style.width = "0%";
  // };

  render() {
    console.log(this.props);
    return (
      <div>
        <Navbar fixed="top" className="header-nav px-5" bg="light" expand="lg">
          <Navbar.Brand href="/"><img style={{height:'65px',width:'120px'}} src={logo} className='img-fluid' /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/">HOME</Nav.Link>
            
              <NavDropdown title="ABOUT US" id="basic-nav-dropdown">
                <NavDropdown.Item className="drop1" href="/about">
                  ABOUT ACADEMY
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item className="drop1" href="/about">
                 WHY CHOOSE US
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item
                  className="myDrop"
                  className="drop1"
                  href="/about"
                >
                  MISSION & VISION
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="PROGRAMS" id="basic-nav-dropdown">
                <NavDropdown.Item className="drop1" href="/ACCAprograms">
                  ACCA
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item className="drop1" href="/tailoredPrograms">
                  TAILORED PROGRAMS
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item
                  className="myDrop"
                  className="drop1"
                  href="/cpdProgram"
                >
                  CPD
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="team">OUR TEAM</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
       
      </div>
    );
  }
}
