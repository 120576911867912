import React, { Component } from "react";
import Header from "../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class About extends Component {
  // constructor(props) {
  //   super(props);
  //   this.about = React.createRef();
  //   this.mission = React.createRef();
  //   this.choose = React.createRef();
  // }

  // handleScrollAbout = (e) => {
  //   const about = this.about.current;
  //   window.scrollTo({
  //     top: about.offsetTop,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };

  // handleScrollMission = (e) => {
  //   const mission = this.mission.current;
  //   window.scrollTo({
  //     top: mission.offsetTop,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };
  // handleScrollChoose = (e) => {
  //   const choose = this.choose.current;
  //   window.scrollTo({
  //     top: choose.offsetTop,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Header
        // handleScrollAbout={this.handleScrollAbout}
        // handleScrollMission={this.handleScrollMission}
        // handleScrollChoose={this.handleScrollChoose}
        />
        <div className="aboutBg">
          <Container>
            <Row>
              <Col sm={5}></Col>
              {/* <Col style={{ height: "70vh" }} className="mainTitle" sm={7}>
                <div className='pt-5 mt-5'>
                  {" "}
                  <h1>About</h1>
                  <h1>The</h1>
                  <h1>Academy</h1>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        {/* <Container className="my-5 p-4">
          <Row>
            <Col sm={12}>
              {" "}
              <h2>ABOUT ACADEMY</h2>
            </Col>
            <Col
              style={{
                textAlign: "justify",
                fontSize: "20px",
                marginTop: "20px",
              }}
              sm={12}
            >
              <p>
                <strong style={{ color: "#800000" }}> ONE ACADEMY</strong> is a
                limited liability company founded in Egypt since 2021. The
                academy provides a range of educational courses including ACCA
                qualifications and tailored accounting, auditing and finance
                programs designed specifically to develop candidates’ knowledge
                and skills. In addition, the academy provides professional
                services.
              </p>
            </Col>
          </Row>
        </Container> */}

        <Container className="homeAbout mt-5">
          <Row>
            <Col sm="12">
              <h2
                style={{
                  textAlign: "center",
                }}
              >
                ABOUT ACADEMY
              </h2>
            </Col>
          </Row>
          <Row>
            <Col className="pt-1" sm="12" md="4">
              <i class="fas fa-chalkboard-teacher"></i>
            </Col>
            <Col className="pt-3" sm="12" md="8">
              <p>
                {" "}
                <strong>ONE ACADEMY</strong> is a limited liability company
                founded in Egypt since 2021. The academy provides a range of
                educational courses including ACCA qualifications and tailored
                accounting, auditing and finance programs designed specifically
                to develop candidates’ knowledge and skills. In addition, the
                academy provides professional services.
              </p>
            </Col>
          </Row>
        </Container>

        {/* <div> */}
          <Container  className="mb-5 homeAbout mt-5">
            <Row className="mt-5">
              <Col className="py-5" sm={12}>
                <h2>WHY CHOOSE ONE ACADEMY</h2>
                <p style={{ fontSize: "18px",color:'#d8b671' ,textAlign:'center'}}>
                  There are many reasons for choosing one academy as your
                  learning provider.
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <h4  className="mb-3">
                  LEARNING METHODOLGY{" "}
                </h4>
                <p className="pAbout">
                  We have a unique methodology for learning which is developed
                  based on the prospective of our team members. The methodology
                  focus on the efficient learning program set a friendly, open
                  and respectful tone during the session with positive
                  reinforcement to maintain positive learning. Our team members
                  are sensitive to the different needs for candidates who need
                  repetition for complicated topics, and are dedicated to
                  circulate their experience to candidates to apply the
                  knowledge in workplace.
                  <br />
                  <strong >SMART NOTES,</strong> We
                  have developed designed notes with extensive of exam questions
                  to help candidates to gain the skills required to tackle the
                  exam with a great confidence to gain high score rate.
                </p>
              </Col>
              <Col sm={6}>
                <h4  className="mb-3">
                  FLEXIBLE TUITION OPTION
                </h4>
                <p className="pAbout">
                  We offer two types of tuition options are face-to-face and
                  online.{" "}
                  <br/>
                  <strong>
                    Face to face tuition
                  </strong>{" "}
                  delivered to our candidates at our selected premises is an
                  excellent way to stay motivated and interactive with the
                  instructor.
                  <br />{" "}
                  <strong>
                    Online tuition
                  </strong>{" "}
                  gives our candidates the flexibility to organize their study
                  and complete coursework in their own time and place, and is a
                  great option if candidates are working full-time or have no
                  access to face-to-face tuition.
                </p>
              </Col>
            </Row>
            <Row className="my-5">
              <Col sm={6}>
                <h4  className="mb-3">
                  OUR VALUE
                </h4>
                <p className="pAbout">
                  Our value comes from our talented team members who comprise
                  from qualified professionals and academics PhDs. Our team
                  members are committed to give our candidates the best learning
                  experience to assist them to prepare for exams and to develop
                  knowledge and skills for their careers to achieve their goals.{" "}
                </p>
              </Col>
              <Col sm={6}>
                <h4  className="mb-3">
                  SUCCESS GUARANTEE
                </h4>
                <p className="pAbout">
                  We are so confident that you will achieve exam success that we
                  offer a Pass{" "}
                  <strong>
                    1ˢᵗ Time guarantee,
                  </strong>{" "}
                  if you do not pass the first time, we will give you a free
                  re-sit.
                </p>
              </Col>
            </Row>
          </Container>
        {/* </div> */}

        <Container  className="mb-5 homeAbout mt-5">
          <Row>
            {" "}
            <Col sm="12">
              {" "}
              <h2 style={{ textAlign: "center" }}>MISSION AND VISION</h2>
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm="6">
              <h4>OUR MISSION</h4>
              <p className="pAbout">
                <strong> ONE ACADEMY</strong>{" "}
                Provides educational services in accounting, auditing and
                finance major in addition to professional services and CPD for
                audit firms. We are making educational smarter and easier, we
                believe that with qualified professionals and PhDs, our clients/
                candidates will gain exceptional educational and professional
                services. We are making sure that our clients/ candidates are
                investing where the best returns are, while building trust
                across every touch point.
              </p>
            </Col>
            <Col sm="6">
              <h4>OUR VISION</h4>
              <p className="pAbout">
                <strong> ONE ACADEMY</strong>
                has a vision to become the leading educational academy in Middle
                East and improving the accountancy profession through providing
                high quality educational services designed to grow a qualified
                generation who are able to meet the challenges in workplaces.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
