import React, { Component } from "react";
import Header from "../../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import img5 from "../../../imgs/img5.jpg";
import img6 from "../../../imgs/pm2.jpeg";
export default class ArabicInterAccount extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <Header />

        <div style={{ marginTop: "80px" }}>
          {/* <div className="pmDiv"> */}
          <div style={{ backgroundColor: "#d8b671" }}>
            {" "}
            <h2
              style={{
                paddingTop: "15px",
                paddingBottom: "23px",
                color: "#fff",
              }}
            >
              المحاسبة المتوسطة{" "}
            </h2>
          </div>
          <Container className="homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PAPER</h4>
              </Col>
            </Row>
            <Row>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 style={{ textAlign: "right" }} className="mb-3">
                        الهدف
                      </h5>
                      <p style={{ textAlign: "right" }} dir="rtl">
                        الهدف من المحاسبة المتوسطة هو تطوير المعرفة والمهارات في
                        فهم وتطبيق معايير المحاسبة المصرية الأساسية اللازمة
                        للاعتراف وقياس المعاملات المحاسبية وإعداد التقارير
                        المالية للشركات، بما في ذلك الإفصاحات المطلوبة.
                      </p>
                    </div>
                    <div className="arSyllabs" className="mt-5">
                      <h5 className="mb-3 text-right">المنهج</h5>
                      <p className="text-right">
                        الاطار العام للتقارير المالية{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        اعداد وعرض القوائم المالية{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        المخزون <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        قائمة التدفقات النقدية{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>{" "}
                      <p className="text-right">
                        السياسات المحاسبية والتقديرات والأخطاء{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الاحداث بعد تاريخ القوائم المالية
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الأصول الثابتة <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        اثار التغيرات فى اسعار صرف العملات الاجنبيه{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        تكلفة الأقتراض <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الافصاح عن الاطراف ذوى العلاقة{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        اضمحلال الأصول الثابتة{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        المخصصات والالتزامات والاصول المحتملة{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الأصول غير الملموسة{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الأستثمار العقارى <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الزراعة <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        التنقيب عن الموارد الطببيعة
                        <i className="fas fa-check ml-3"></i>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="py-3" sm="12" md="6">
                <img src={img5} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>

          <Container className="my-5 homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PROGRAM</h4>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" className="py-3">
                <img
                  src={img6}
                  alt=""
                  style={{ height: "438px" }}
                  className="img-fluid"
                />
              </Col>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 className="mb-3 text-right">خصائص البرنامج</h5>
                      <p className="text-right">
                        عدد المحاضرات: 10 <i className="fas fa-check mr-3"></i>
                      </p>

                      <p className="text-right">
                        مدة المحاضرة: 3 ساعات{" "}
                        <i className="fas fa-check mr-3"></i>
                      </p>

                      <p className="text-right">
                        طريقة التدريس: حضور/ اونلاين{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="mb-3 text-right">منهجية التدريس </h5>
                      <p className="text-right">
                        ملازم للمنهج <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الشرح والتطبيق العملي
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        امتحان تقييم <i className="fas fa-check ml-3"></i>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <h4 style={{ color: "#d8b671" }} className="text-center">
            تكلفة البرنامج
          </h4>

          <div>
            <Container
              className="customContainer"
              style={{ overflow: "hidden" }}
            >
              <Row className="">
                <Col className="colCards text-left" sm="12" md="12">
                  <Row className="mt-2 rowProgram">
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="5"
                    >
                      <i class="fas fa-graduation-cap"></i>
                      <h5 dir="rtl" style={{ fontSize: "17px" }}>
                        محاضرات الشرح "9 محاضرات"{" "}
                      </h5>
                      <p dir="rtl">
                        تقديم شرحًا مفصلاً لمحتويات المنهج الدراسي مع توضيح لكل
                        نقطة للتأكد من أن الطلاب يتفهمون الموضوع بأكمله وبوضوح،
                        إضافة إلى أسئلة التدريب بعد كل محاضرة مع المتابعة من
                        المحاضر.
                      </p>
                    </Col>
                    <Col className="" sm="12" md="1"></Col>
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="5"
                    >
                      <i class="fas fa-graduation-cap"></i>

                      <h5 dir="rtl" style={{ fontSize: "17px" }}>
                        امتحان تقييم "محاضرة"{" "}
                      </h5>
                      <p dir="rtl">
                        يتم اختبار الطلاب بعد الأنتهاء من تقديم المحاضرات
                        التعليمية لهم وذلك لتحفيزهم للمذاكرة والتدريب وايضا
                        لتحديد وتقييم مدى استيعابهم للمادة العلمية المقدمة لهم
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
