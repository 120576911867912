import React, { Component } from "react";
import Header from "../../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import img5 from "../../../imgs/img5.jpg";
import img6 from "../../../imgs/pm2.jpeg";
export default class Auditing extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <Header />

        <div style={{ marginTop: "80px" }}>
          {/* <div className="pmDiv"> */}
          <div style={{ backgroundColor: "#d8b671" }}>
            {" "}
            <h2
              style={{
                paddingTop: "15px",
                paddingBottom: "23px",
                color: "#fff",
              }}
            >
              Audit and Assurance{" "}
            </h2>
          </div>
          <Container className="homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PAPER</h4>
              </Col>
            </Row>
            <Row>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 className="mb-3">Aim</h5>
                      <p>
                        The aim of Audit and Assurance is to develop knowledge
                        and skills for the auditors in understanding and
                        applying When performing the audit process for the audit
                        client, the program includes explanation and practical
                        application of how to perform the audit process, which
                        includes the stage of acceptance and continuity with
                        clients, the stage of planning the audit process, the
                        stage of implementing the audit process, performing the
                        audit procedures to obtain sufficient and appropriate
                        audit evidence, and finally the stage of completing the
                        audit process and performing the final audit procedures
                        and forming audit report.
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="mb-3">The Syllabus</h5>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Ethics and
                        behaviors of the accounting and auditing profession
                        (basic principles - threats - protection tools)
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Accepting a new
                        client - continuing with the client
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Engagement letter
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Planning the audit
                        process (Audit strategy - Audit plan)
                      </p>{" "}
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Understand the
                        entity and its environment, including its internal
                        control system
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Identifying and
                        assessing inherent risk
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Identifying and
                        evaluating control risk
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Analytical
                        procedures
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Fraud assessment
                        procedures
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Materiality
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Engagement team
                        discussion
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Design and
                        implementation of control tests
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Design and
                        implement detail tests
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Methods of design
                        and selection of samples
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Reviewing
                        accounting estimates
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> How to prepare
                        working papers and reach conclusions
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Using the work of
                        others (use of expert work - use of internal audit work
                        - service entity)
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Subsequent events
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Going concern
                        Assessment
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Evaluate
                        uncorrected misstatements
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> representation
                        letter
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Auditor's report
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Management letter
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="py-3" sm="12" md="6">
                <img src={img5} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>

          <Container className="my-5 homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PROGRAM</h4>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" className="py-3">
                <img
                  src={img6}
                  alt=""
                  style={{ height: "438px" }}
                  className="img-fluid"
                />
              </Col>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 className="mb-3">Key Features</h5>
                      <p>
                        <i className="fas fa-check mr-3"></i>Duration: 2.5
                        Months
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Sessions: 10
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Session Duration: 3
                        Hrs
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Material: Smart
                        Notes
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Study Mode:
                        Face-To-Face/ Online
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="mb-3">Tuition Methodology </h5>
                      <p>
                        <i className="fas fa-check mr-3"></i> Smart Notes
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i> Explanation and
                        illustration
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i> Assessment based
                        exam
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <h4 style={{ color: "#d8b671" }} className="text-center">
            PROGRAM PACKAGE
          </h4>

          <div>
            <Container
              className="customContainer"
              style={{ overflow: "hidden" }}
            >
              <Row className="">
                <Col className="colCards text-left" sm="12" md="12">
                  <Row className="mt-2 rowProgram">
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="5"
                    >
                      <i class="fas fa-graduation-cap"></i>
                      <h5 style={{ fontSize: "17px" }}>
                        Tuition Classes "9 Sessions"
                      </h5>
                      <p>
                        Provide detailed explanation and application of the
                        Egyptian Standards on Auditing and application of
                        preparing working papers and audit file.
                      </p>
                    </Col>
                    <Col className="" sm="12" md="1"></Col>
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="5"
                    >
                      <i class="fas fa-graduation-cap"></i>

                      <h5 style={{ fontSize: "17px" }}>
                        Revision Classes "1 Sessions"
                      </h5>
                      <p>
                        We offer assessment based exam after the end of tuition
                        classes to motivate candidates to study and practice and
                        to evaluate the candidates’ progress.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
