import React, { Component } from "react";
import Header from "../../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import img5 from "../../../imgs/img5.jpg";
import img6 from "../../../imgs/pm2.jpeg";
export default class AuditAR extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <Header />

        <div style={{ marginTop: "80px" }}>
          {/* <div className="pmDiv"> */}
          <div style={{ backgroundColor: "#d8b671" }}>
            {" "}
            <h2
              style={{
                paddingTop: "15px",
                paddingBottom: "23px",
                color: "#fff",
              }}
            >
              المحاسبة النهائي{" "}
            </h2>
          </div>
          <Container className="homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PAPER</h4>
              </Col>
            </Row>
            <Row>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 style={{ textAlign: "right" }} className="mb-3">
                        الهدف
                      </h5>
                      <p style={{ textAlign: "right" }} dir="rtl">
                        الهدف من برنامج المراجعة وخدمات التأكد هو تطوير المعرفة
                        والمهارات لدى مراجع الحسابات في فهم وتطبيق المعايير
                        المصرية للمراجعة عند القيام بأداء عملية المراجعة للعميل،
                        حيث يثضمن البرنامج الشرح والتطبيق العملى لكيفية اداء
                        عملية المراجعة والتى تتضمن مرحلة قبول والاستمرار مع
                        العملاء ومرحلة التخطيط لعملية المراجعة ومرحلة تنفيذ
                        عملية المراجعة واداء اجراءات المراجعة للحصول على الأدلة
                        الكافية والملائمة واخيرا مرحلة الأنتهاء من عملية
                        المراجعة واداء اجراءات المراجعة النهائية واصدار تقرير
                        مراقب الحسابات.
                      </p>
                    </div>
                    <div className="arSyllabs" className="mt-5">
                      <h5 className="mb-3 text-right">المنهج</h5>
                      <p className="text-right">
                        اداب وسلوكيات مهنة المحاسبة والمراجعة (المبادىء الاساسية
                        - التهديدات - ادوات الحماية){" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        قبول عميل جديد - الاستمرار مع العميل{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        خطاب الأرتباط <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        التخطيط لعملية المراجعة (استراتيجية المراجعة - خطة
                        المراجعة) <i className="fas fa-check ml-3"></i>
                      </p>{" "}
                      <p className="text-right">
                        تفهم المنشأة وبيئتها بما فى ذلك نظام الرقابة الداخلية
                        بها <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        اجراءات تحديد وتقييم الخطر المتأصل{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        اجراءات تحديد وتقييم خطر الرقابة{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الأجراءات التحليلية{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        اجراءات تقييم الغش <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الاهمية النسبية <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        مناقشة اعضاء فريق العمل{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        تصميم وتنفيذ اختبارات الرقابة{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        تصميم وتنفيذ اختبارات التفاصيل{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        طرق تصميم واختيار العينات{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        مراجعة التقديرات المحاسبية{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        كيفية اعداد اوراق العمل والتوصل الى الأستنتاجات{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        استخدام عمل الاخرين (استخدام عمل خبير - استخدام عمل
                        المراجعة الداخلية - المنشأة الخدمية){" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الأحداث اللاحقة <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        تقييم الاستمرارية
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        تقييم الأخطاء غير المصححة{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        خطاب التمثيل <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        تقرير مراقب الحسابات{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        خطاب الادارة <i className="fas fa-check ml-3"></i>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="py-3" sm="12" md="6">
                <img src={img5} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>

          <Container className="my-5 homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PROGRAM</h4>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" className="py-3">
                <img
                  src={img6}
                  alt=""
                  style={{ height: "438px" }}
                  className="img-fluid"
                />
              </Col>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 className="mb-3 text-right">خصائص البرنامج</h5>
                      <p className="text-right">
                        عدد المحاضرات: 10 <i className="fas fa-check mr-3"></i>
                      </p>

                      <p className="text-right">
                        مدة المحاضرة: 3 ساعات{" "}
                        <i className="fas fa-check mr-3"></i>
                      </p>

                      <p className="text-right">
                        طريقة التدريس: حضور/ اونلاين{" "}
                        <i className="fas fa-check ml-3"></i>
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="mb-3 text-right">منهجية التدريس </h5>
                      <p className="text-right">
                        ملازم للمنهج <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        الشرح والتطبيق العملي
                        <i className="fas fa-check ml-3"></i>
                      </p>
                      <p className="text-right">
                        امتحان تقييم <i className="fas fa-check ml-3"></i>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <h4 style={{ color: "#d8b671" }} className="text-center">
            تكلفة البرنامج
          </h4>

          <div>
            <Container
              className="customContainer"
              style={{ overflow: "hidden" }}
            >
              <Row className="">
                <Col className="colCards text-left" sm="12" md="12">
                  <Row className="mt-2 rowProgram">
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="5"
                    >
                      <i class="fas fa-graduation-cap"></i>
                      <h5 dir="rtl" style={{ fontSize: "17px" }}>
                        محاضرات الشرح "9 محاضرات"{" "}
                      </h5>
                      <p dir="rtl">
                        تقديم شرحًا مفصلاً للمعايير المصرية للمراجعة بالأضافية
                        الى كيفية التطبيق العملى لأعداد اوراق العمل وملف
                        المراجعة.
                      </p>
                    </Col>
                    <Col className="" sm="12" md="1"></Col>
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="5"
                    >
                      <i class="fas fa-graduation-cap"></i>

                      <h5 dir="rtl" style={{ fontSize: "17px" }}>
                        امتحان تقييم "محاضرة"{" "}
                      </h5>
                      <p dir="rtl">
                        يتم اختبار الطلاب بعد الأنتهاء من تقديم المحاضرات
                        التعليمية لهم وذلك لتحفيزهم للمذاكرة والتدريب وايضا
                        لتحديد وتقييم مدى استيعابهم للمادة العلمية المقدمة لهم.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
