import React, { Component } from "react";
import Header from "../../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import img5 from "../../../imgs/img5.jpg";
import img6 from "../../../imgs/pm2.jpeg";
export default class ATX extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <Header />

        <div style={{ marginTop: "80px" }}>
          {/* <div className="pmDiv"> */}
          <div style={{ backgroundColor: "#d8b671" }}>
            {" "}
            <h2
              style={{
                paddingTop: "15px",
                paddingBottom: "23px",
                color: "#fff",
              }}
            >
              Advanced Audit and Assurance (AAA-INT){" "}
            </h2>
          </div>
          <Container className="homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PAPER</h4>
              </Col>
            </Row>
            <Row>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 className="mb-3">Aim</h5>
                      <p>
                        The aim of AAA is to analyse, evaluate and conclude on
                        the assurance engagement and other audit and assurance
                        issues in the context of best practice and current
                        developments. The syllabus covers legal and regulatory
                        environment including money laundering, and professional
                        and ethical considerations, including the Code of Ethics
                        and professional liability. This then leads into
                        procedures in practice management, including quality
                        control and the acceptance and retention of professional
                        engagements. In addition to the audit of financial
                        statements, including planning and evidence gathering
                        which covers the completion, evidence evaluation and
                        review and reporting on an audit of historical financial
                        information. The syllabus also covers other assignments
                        including prospective financial information, due
                        diligence and forensic audit as well as the reporting of
                        these assignments.
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="mb-3">The Syllabus</h5>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Regulatory
                        Environment
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Professional and
                        Ethical Considerations
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Quality Control
                        and Practice Management
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Planning and
                        conducting an audit of historical financial information
                      </p>{" "}
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Completion, review
                        and reporting
                      </p>{" "}
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Current Issues and
                        Developments
                      </p>{" "}
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> Employability and
                        technology skills
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="py-3" sm="12" md="6">
                <img src={img5} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>

          <Container className="my-5 homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PROGRAM</h4>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" className="py-3">
                <img
                  src={img6}
                  alt=""
                  style={{ height: "438px" }}
                  className="img-fluid"
                />
              </Col>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 className="mb-3">Key Features</h5>
                      <p>
                        <i className="fas fa-check mr-3"></i>Duration: 3 Months
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Sessions: 16
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Session Duration: 3
                        Hrs
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Material: Smart
                        Notes
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Study Mode:
                        Face-To-Face/ Online
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="mb-3">Tuition Methodology </h5>
                      <p>
                        <i className="fas fa-check mr-3"></i>Explanation and
                        illustration
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Exam technique
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Time management
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <h4 style={{ color: "#d8b671" }} className="text-center">
            PROGRAM PACKAGE
          </h4>

          <div>
            <Container
              className="customContainer"
              style={{ overflow: "hidden" }}
            >
              <Row className="">
                <Col className="colCards text-left" sm="12" md="12">
                  <Row className="mt-2 rowProgram">
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="4"
                    >
                      <i class="fas fa-graduation-cap"></i>
                      <h5 style={{ fontSize: "17px" }}>
                        Tuition Classes "10 Sessions"
                      </h5>
                      <p>
                        Provide detailed explanation of the syllabus contents
                        with illustration for each point to ensure that our
                        candidates understand the whole subject clearly and
                        gained the detailed information, which could reveal in
                        the exam session. Addition to homework practice
                        questions with follow up by the tutor.
                      </p>
                    </Col>
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="4"
                    >
                      <i class="fas fa-graduation-cap"></i>

                      <h5 style={{ fontSize: "17px" }}>
                        Revision Classes "5 Sessions"
                      </h5>
                      <p>
                        Help candidates to refresh their knowledge and skills
                        after they have attended a tuition classes, as well as a
                        reminder of the syllabus content, candidates will get
                        intensive exam question practice and dedicated tutor
                        support. Revision classes are a great experience to
                        prepare for an exam, whether it is for a first-time
                        sitting or a re-sit.
                      </p>
                    </Col>
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="4"
                    >
                      <i class="fas fa-graduation-cap"></i>

                      <h5 style={{ fontSize: "17px" }}>
                        Mock Exam "1 Session"{" "}
                      </h5>
                      <p>
                        We offer mock exam after the end of revision to put
                        students in the exam conditions, mock exam will be
                        prepared in the light of the examiner's report published
                        by ACCA.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
