import React, { Component } from "react";
import Header from "../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
export default class AccaPrograms extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <Header />

        {/* <video
          width="100%"
          autoPlay
          muted
          loop
          className="myVideo"
          id="my-video"
        >
          <source src={video1} type="video/mp4" />
        </video> */}
        <div className="accaPro">
          <Container>
            <Row>
              <Col sm={5}></Col>
              {/* <Col style={{ height: "60vh" }} className="mainTitle" sm={7}>
              <div className='pt-5 mt-5'>

                <h1>ACCA</h1>
                <h1>Programms</h1>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>

        <Container className="customContainer" style={{ overflow: "hidden" }}>
          <Row className="mt-2">
            <Col className="colCards text-left" sm="12" md="12">
              <Row className="mt-2 rowProgram">
                <Col className=" px-5 pt-4 pb-5 mb-3 mr-4" sm="12" md="1"></Col>
                <Col
                  className="programBox1 px-5 pt-4 pb-5 mb-3"
                  sm="12"
                  md="5"
                >
                  <h5>ACCA QUALIFICATION</h5>
                  <p>
                    ACCA Qualification gives students the most up-to-date skills
                    they need to become finance professional and to meet the
                    workplace needs.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/acca">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col
                  className="programBox1 px-5 pt-4 pb-5 mb-3 mr-3"
                  sm="12"
                  md="5"
                >
                  <h5>IFRS DIPLOMA</h5>
                  <p>
                    IFRS Diploma is designed to develop students’ knowledge and
                    understanding of International Financial Reporting
                    Standards, In addition to apply these standards in the
                    workplace.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/Ifrsprograms">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col className=" px-5 pt-4 pb-5 mb-3 mr-4" sm="12" md="1"></Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {/* <Container className="mb-5">
          <Row className="mt-2">
          </Row>
          <Row className="mt-5">
            <Col className="programBox1 p-5 mb-4" sm="12" md="5">
              <h5>ACCA QUALIFICATION</h5>
              <p>
                ACCA Qualification gives students the most up-to-date skills
                they need to become finance professional and to meet the
                workplace needs.
              </p>
              <p className="check">
                {" "}
                <Link to="/acca">
                  Checkout Program
                  <i className="fas fa-arrow-right px-2"></i>
                </Link>
              </p>
            </Col>
            <Col sm="12" md="2"></Col>
            <Col className="programBox1 p-5 " sm="12" md="5">
              <h5>IFRS DIPLOMA</h5>
              <p>
                IFRS Diploma is designed to develop students’ knowledge and
                understanding of International Financial Reporting Standards, In
                addition to apply these standards in the workplace.
              </p>
              <p className="check">
                {" "}
                <Link to="/Ifrsprograms">
                  Checkout Program
                  <i className="fas fa-arrow-right px-2"></i>
                </Link>
              </p>
            </Col>
          </Row>
        </Container> */}
      </div>
    );
  }
}
