import React, { Component } from "react";
import Header from "../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
export default class Auditing extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="interAudit">
          <Container>
            <Row>
              <Col sm={4}></Col>
              <Col className="mainTitle" sm={8}>
                <h1>OUR</h1>
                <h1>ADVANCED</h1>
                <h1>AUDITING</h1>
                <h1>PROGRAM</h1>
              </Col>
            </Row>
          </Container>
        </div>{" "}
        <Container className="my-5">
          <Row>
            <Col sm="12">
              <h2>AUDITING</h2>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col sm="11">
              <h4 style={{ color: "#800000", textAlign: "left" }}>
                ADVANCED AUDITING{" "}
              </h4>
              <p
                style={{
                  color: "#0c3c60",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Program objective
              </p>
              <p className="pAbout text-left">
                The program contains auditing standards, which required for
                evaluate and conclude on the assurance engagement and other
                audit and assurance issues including quality control.
              </p>

              <p
                style={{
                  color: "#0c3c60",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Potential candidates
              </p>
              <p className="pAbout text-left">
                Our tailored advanced auditing program designed to meet the
                workplace needs for auditors in senior level.
              </p>

              <p
                style={{
                  color: "#0c3c60",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                On successful completion of this program, candidates should be
                able to:
              </p>
              <ul>
                <li className="list1">
                  Demonstrate the ability to work effectively on an assurance
                  or other service engagement.
                </li>
                <li className="list1">
                  Identify and assess the material risks and design the
                  response to those risks{" "}
                </li>
                <li className="list1"> Use of work of others</li>
                <li className="list1">
                  Evaluate findings and the results of work performed and
                  draft suitable reports on assignments.{" "}
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <p
                style={{
                  color: "#0c3c60",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Our tailored material is well designed by our professional
                experts and contains :
              </p>
              <ul>
                <li className="list1">
                  Detailed explanation of the advanced and complicated
                  auditing standards’
                </li>
                <li className="list1">
                  Illustrations and examples for each point.{" "}
                </li>
                <li className="list1"> Practice questions.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <p
              style={{
                color: "#0c3c60",
                textAlign: "left",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Tuition methodology
            </p>
            <p className="pAbout">
              Unique methodology of explanation of the advanced and complicated
              auditing standards' knowledge with guidance of application in
              workplace.
            </p>
          </Row>
          <Row className="mt-5">
            <Col sm="12">
              <h2>Program Package</h2>
            </Col>
          </Row>
          <Row className="my-2">
            <Col sm="12" md="6">
              <h4 style={{ color: "#0c3c60" }} className="mb-3">
                Revision classes "8 session
              </h4>
              <p className="pAbout">
                provide detailed explanation of the syllabus contents with
                illustration for each point to ensure that our candidates
                understand the whole subject clearly and gained the detailed
                information. Addition to homework practice questions with follow
                up by the tutor.
              </p>
            </Col>
            <Col sm="12" md="6">
              <h4 style={{ color: "#0c3c60" }} className="mb-3">
                Tuition classes "4 sessions
              </h4>
              <p className="pAbout">
                help candidates to refresh their knowledge and skills after they
                have attended a tuition classes, as well as a reminder of the
                syllabus content, candidates will get intensive practice
                questions and dedicated tutor support
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <p
                style={{
                  fontSize: "18px",
                  color: "#0c3c60",
                  fontWeight: "bold",
                }}
              >
                Candidates may enroll to whole package or choose between them
                based on their needs.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
