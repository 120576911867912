import React, { Component } from "react";
import { Col, Container, Row, Button, Card, CardDeck } from "react-bootstrap";

import img1 from "../../imgs/img1.jpg";
import img2 from "../../imgs/img2.jpg";
import { Link } from "react-router-dom";
import Header from "../layout/Header";
import video1 from "../../imgs/video1.mp4";
// import Footer from "../layout/Footer";
import team from "../../imgs/team.png";
export default class Home extends Component {
  // constructor(props) {
  //   super(props);
  //   this.about = React.createRef();
  //   this.mission = React.createRef();
  //   this.contact = React.createRef();
  // }

  // handleScrollAbout = (e) => {
  //   const about = this.about.current;
  //   window.scrollTo({
  //     top: about.offsetTop,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };

  // handleScrollMission = (e) => {
  //   const mission = this.mission.current;
  //   window.scrollTo({
  //     top: mission.offsetTop,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };
  // handleScrollContact = (e) => {
  //   const contact = this.contact.current;
  //   window.scrollTo({
  //     top: contact.offsetTop,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    // const settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   autoplay: true,
    // };
    return (
      <div>
        <Header />
        <div style={{ marginTop: "65px" }}>
          <video
            width="100%"
            // height="1000"
            autoPlay
            muted
            loop
            className="myVideo"
            id="my-video"
          >
            <source src={video1} type="video/mp4" />
          </video>
          {/* <div className="homeBg">
          <Container>
            <Row>
              <Col sm={5}></Col>
              <Col className="mainTitle" sm={7}>
                <h1>One</h1>
                <h1>Academy</h1>
                <p>
                  leading professional and educational academy in Egypt, which
                  provide professional and educational services that related to
                  the accounting and auditing career, our team members are
                  professionals and qualified
                </p>
                
              </Col>
            </Row>
          </Container>
        </div> */}

          <div>
            <Container className="homeAbout mt-5">
              <Row>
                <Col sm="12">
                  <h2
                    style={{
                      textAlign: "center",
                    }}
                  >
                    ABOUT US
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col className="pt-1" sm="12" md="4">
                  <Link to="/about">
                    <i class="fas fa-chalkboard-teacher"></i>
                  </Link>
                </Col>
                <Col className="pt-3" sm="12" md="8">
                  <p>
                    {" "}
                    <strong>ONE ACADEMY</strong> is a limited liability company
                    founded in Egypt since 2021. The academy provides a range of
                    educational courses including ACCA qualifications and
                    tailored accounting, auditing and finance programs designed
                    specifically to develop candidates’ knowledge and skills. In
                    addition, the academy provides professional services.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          <Container className="customContainer" style={{ overflow: "hidden" }}>
            <Row className="mt-2">
              <Col  className="text-left colCards" sm="12" md="12">
                <Row className="mt-2 rowProgram">
                  <Col
                    className="programBox px-5 pt-4 pb-5 mb-3"
                    sm="12"
                    md="4"
                  >
                    <h5 style={{ fontSize: "17px" }}>ACCA</h5>
                    <p>
                      ACCA Qualification gives you the most up-to-date skills
                      you need to become finance professional and to meet the
                      workplace needs. Upon you became qualified you will open
                      the doors to the best and most interesting roles all over
                      the world.
                    </p>
                    <p className="check">
                      {" "}
                      <Link to="/ACCAprograms">
                        Checkout Program
                        <i className="fas fa-arrow-right px-2"></i>
                      </Link>
                    </p>
                  </Col>
                  <Col
                    className="programBox px-5 pt-4 pb-5 mb-3"
                    sm="12"
                    md="4"
                  >
                    <h5 style={{ fontSize: "17px" }}>TAILORED PROGRAMS </h5>
                    <p>
                      Expert team of ONE ACADEMY has developed tailored programs
                      to gives you the knowledge and skills required to become a
                      professional in your career and to meet the workplace
                      needs. Upon completion of the tailored program you will
                      increase opportunities to join the job you have always
                      dream.
                    </p>
                    <p className="check">
                      {" "}
                      <Link to="/tailoredPrograms">
                        Checkout Program
                        <i className="fas fa-arrow-right px-2"></i>
                      </Link>
                    </p>
                  </Col>
                  <Col
                    className="programBox px-5 pt-4 pb-5 mb-3"
                    sm="12"
                    md="4"
                  >
                    <h5 style={{ fontSize: "17px" }}>CPD </h5>
                    <p>
                      CPD gives you access updated professional and technical
                      contents that ensure both your knowledge and skills are
                      maintained up to date, so you remain professionally
                      competent.
                    </p>
                    <p className="check">
                      {" "}
                      <Link to="/cpdProgram">
                        Checkout Program
                        <i className="fas fa-arrow-right px-2"></i>
                      </Link>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Container className="teamHome mt-5">
            <Row>
              <Col sm="12">
                <h2
                  style={{
                    textAlign: "center",
                  }}
                >
                  OUR TEAM
                </h2>
              </Col>
            </Row>
            <Row>
              <Col className="" sm="12" md="4">
                <Link to="/team">
                  <i class="fas fa-users"></i>
                </Link>
              </Col>
              <Col className="pt-3" sm="12" md="8">
                <p>
                  <strong>ONE ACADEMY</strong> has a talented team who is
                  responsible for delivering high quality of educational and
                  professional services to ensure success and adding value to
                  our candidates.
                </p>
                {/* <Button className="homeBtn mt-2">
                <Link to="/team">Checkout The Team</Link>
              </Button> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
