import React, { Component } from "react";
import Header from "../../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import img5 from "../../../imgs/img5.jpg";
import img6 from "../../../imgs/pm2.jpeg";
export default class AdvancedAccount extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <Header />

        <div style={{ marginTop: "80px" }}>
          {/* <div className="pmDiv"> */}
          <div style={{ backgroundColor: "#d8b671" }}>
            {" "}
            <h2
              style={{
                paddingTop: "15px",
                paddingBottom: "23px",
                color: "#fff",
              }}
            >
              Advanced Accounting{" "}
            </h2>
          </div>
          <Container className="homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PAPER</h4>
              </Col>
            </Row>
            <Row>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 className="mb-3">Aim</h5>
                      <p>
                        The aim of Advanced Accounting is to develop knowledge
                        and skills in understanding and applying complicated
                        Egyptian Accounting Standards in recognition and
                        measurement of the transactions and the preparation of
                        financial statements of entities, including groups and
                        disclosures required.
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="mb-3">The Syllabus</h5>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Financial Instruments
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Investments in Associates
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Joint Arrangements
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Business combination
                      </p>{" "}
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Consolidated financial statements
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Revenue from contract with customers
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Leases
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Employee Benefits
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Share based payments
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Earnings per share
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Non-current Assets Held for sale and Discontinued Operations
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Operating Segments
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Fair value measurement
                      </p>
                      <p className="text-left">
                        <i className="fas fa-check mr-3"></i> 	Income Taxes 
                      </p>
                    
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="py-3" sm="12" md="6">
                <img src={img5} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>

          <Container className="my-5 homeAbout">
            <Row>
              <Col sm="12">
                <h4>ABOUT THE PROGRAM</h4>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" className="py-3">
                <img
                  src={img6}
                  alt=""
                  style={{ height: "438px" }}
                  className="img-fluid"
                />
              </Col>
              <Col className="py-3" sm="12" md="6">
                <Row>
                  <Col className="about4 px-5 py-3 text-left" sm="12">
                    <div>
                      <h5 className="mb-3">Key Features</h5>
                      <p>
                        <i className="fas fa-check mr-3"></i>Duration: 2.5
                        Months
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Sessions: 10
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Session Duration: 3
                        Hrs
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Material: Smart
                        Notes
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i>Study Mode:
                        Face-To-Face/ Online
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="mb-3">Tuition Methodology </h5>
                      <p>
                        <i className="fas fa-check mr-3"></i> Smart Notes
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i> Explanation and
                        illustration
                      </p>
                      <p>
                        <i className="fas fa-check mr-3"></i> Assessment based
                        exam
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <h4 style={{ color: "#d8b671" }} className="text-center">
            PROGRAM PACKAGE
          </h4>

          <div>
            <Container
              className="customContainer"
              style={{ overflow: "hidden" }}
            >
              <Row className="">
                <Col className="colCards text-left" sm="12" md="12">
                  <Row className="mt-2 rowProgram">
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="5"
                    >
                      <i class="fas fa-graduation-cap"></i>
                      <h5 style={{ fontSize: "17px" }}>
                        Tuition Classes "9 Sessions"
                      </h5>
                      <p>
                        Provide detailed explanation of the syllabus contents
                        with illustration for each point to ensure that our
                        candidates understand the whole subject clearly and
                        gained the detailed information, which could reveal in
                        the exam session. Addition to homework practice
                        questions with follow up by the tutor.
                      </p>
                    </Col>
                    <Col className="" sm="12" md="1"></Col>
                    <Col
                      className="programBox px-5 pt-4 pb-5 mb-3"
                      sm="12"
                      md="5"
                    >
                      <i class="fas fa-graduation-cap"></i>

                      <h5 style={{ fontSize: "17px" }}>
                        Revision Classes "1 Sessions"
                      </h5>
                      <p>
                        We offer assessment based exam after the end of tuition
                        classes to motivate candidates to study and practice and
                        to evaluate the candidates’ progress.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
