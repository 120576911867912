import React, { Component } from "react";
import { Col, Container, Row, Button, Card, CardDeck } from "react-bootstrap";
import Header from "../layout/Header";

export default class ProgramDetails extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="detailsBg"></div>
        <Container className="mt-5">
          <Row>
            <h3>ACCA QUALIFICATION</h3>
          </Row>
          <Row>
            <Col style={{ textAlign: "left" }} sm={12} md="9">
              <p style={{ textAlign: "left", paddingTop: "20px" }}>
                ACCA Qualification gives students the most up-to-date skills
                they need to become finance professional and to meet the
                workplace needs, it is a long journey to become ACCA member
                which required to achieve three stages of exams, plus an Ethics
                and Professional Skills module and a Professional Experience
                Requirement Where the most students apply for ACCA qualification
                is graduated from colleges hence they are exempted from the
                first stage of exams which include 4 papers.
                <br /> Our qualified learning partners have the skills and
                experience required to guide you through your ACCA qualification
                journey and help you to pass the remaining papers and complete
                your journey to become one of ACCA family.
                <br /> In addition to the deep knowledge you will gain from the
                course, our learning partners will teach you the right technique
                and time management to tackle your exams. We provide tuition
                courses for applied skills and strategic professional levels.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <h6 className="text-left">
                Applied skills level includes the following exams
              </h6>
            </Col>
            <Col className="text-left" sm={12}>
              <ul>
                <li className="text-left">Corporate and Business Law (LW)</li>
                <li className="text-left">Performance Management (PM)</li>
                <li className="text-left">Taxation (TX)</li>
                <li className="text-left">Financial reporting (FR)</li>
                <li className="text-left">Audit and Assurance (AA)</li>
                <li className="text-left">Financial Management (FM)</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <h6 className="text-left">
                Strategic professional level includes the following exams.
              </h6>
            </Col>
            <Col className="text-left" sm={12}>
              <ul>
                <li className="text-left">Strategic Business Leader (SBL)</li>
                <li className="text-left">
                  Strategic Business Reporting (SBR)
                </li>
                <li className="text-left">
                  Advanced Financial Management (AFM)
                </li>
                <li className="text-left">
                  Advanced Performance Management (APM)
                </li>
                <li className="text-left">Advanced Taxation (ATX)</li>
                <li className="text-left">
                  Advanced Audit and Assurance (AAA)
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
