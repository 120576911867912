import React, { Component } from "react";
import Header from "../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
export default class AdvancedFinance extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="interFinance">
          <Container>
            <Row>
              <Col sm={4}></Col>
              <Col className="mainTitle" sm={8}>
                <h1>OUR</h1>
                <h1>ADVANCED</h1>
                <h1>FINANCE</h1>
                <h1>PROGRAM</h1>
              </Col>
            </Row>
          </Container>
        </div>        <Container className="my-5">
          <Row>
            <Col sm="12">
              <h2>FINANCE</h2>
            </Col>
          </Row>
          
          <Row className="mt-5">
            <Col sm="11">
              <h4 style={{ color: "#800000", textAlign: "left" }}>
                ADVANCED FINANCE{" "}
              </h4>
              <p
                style={{
                  color: "#0c3c60",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Program objective
              </p>
              <p className="pAbout text-left">
                The program designed to develop the knowledge, skills and
                exercise professional judgment as expected of a senior financial
                executive or advisor, in taking or recommending decisions
                relating to the financial management of organizations.
              </p>

              <p
                style={{
                  color: "#0c3c60",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Potential candidates
              </p>
              <p className="pAbout text-left">
                Our tailored advanced auditing program designed to meet the
                workplace needs for auditors in senior level.
              </p>

              <p
                style={{
                  color: "#0c3c60",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                On successful completion of this program, candidates should be
                able to:
              </p>
              <ul>
                <li className="list1">
                  Explain and evaluate the role and responsibility of the
                  senior financial executive or advisor.
                </li>
                <li className="list1">
                  Evaluate potential investment decisions and assessing their
                  financial and strategic consequences.
                </li>
                <li className="list1">
                  Assess and plan acquisitions and mergers as an alternative
                  growth strategy.
                </li>
                <li className="list1">
                  Evaluate and advise on alternative corporate re-organization
                  strategies.
                </li>
                <li className="list1">
                  Apply and evaluate alternative advanced treasury and risk
                  management techniques{" "}
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <p
                style={{
                  color: "#0c3c60",
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Our tailored material is well designed by our professional
                experts and contains :
              </p>
              <ul>
                <li className="list1">
                  Detailed explanation of the advanced and complicated finance
                  models.
                </li>
                <li className="list1">
                  Illustrations and examples for each point.{" "}
                </li>
                <li className="list1">Practice questions.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <p
              style={{
                color: "#0c3c60",
                textAlign: "left",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Tuition methodology
            </p>
            <p className="pAbout">
              Unique methodology of explanation of advanced and complicated
              finance models techniques with guidance of application in
              workplace.
            </p>
          </Row>
          <Row className="mt-5">
            <Col sm="12">
              <h2>Program Package</h2>
            </Col>
          </Row>
          <Row className="my-2">
            <Col sm="12" md="6">
              <h4 style={{ color: "#0c3c60" }} className="mb-3">
                Revision classes "8 session
              </h4>
              <p className="pAbout">
                provide detailed explanation of the syllabus contents with
                illustration for each point to ensure that our candidates
                understand the whole subject clearly and gained the detailed
                information. Addition to homework practice questions with follow
                up by the tutor.
              </p>
            </Col>
            <Col sm="12" md="6">
              <h4 style={{ color: "#0c3c60" }} className="mb-3">
                Tuition classes "4 sessions
              </h4>
              <p className="pAbout">
                help candidates to refresh their knowledge and skills after they
                have attended a tuition classes, as well as a reminder of the
                syllabus content, candidates will get intensive practice
                questions and dedicated tutor support.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <p
                style={{
                  fontSize: "18px",
                  color: "#0c3c60",
                  fontWeight: "bold",
                }}
              >
                Candidates may enroll to whole package or choose between them
                based on their needs.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
