import React, { Component } from "react";
import Header from "../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
export default class AccaPrograms extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <Header />
        {/* <div className='pt-5 mt-5'>
        <h2 className="mb-4">ACCA QUALIFICATION</h2>
        </div> */}
        <div className="accaBG" style={{ marginTop: "50px" }}></div>

        <Container className="homeAbout mt-5">
          <Row>
            <Col sm={12} md={6}>
              <div className="p-3">
                <h5 className="mb-1">ABOUT ACCA QUALIFICATION</h5>
                <p>
                  ACCA Qualification gives you the most up-to-date skills you
                  need to become finance professional and to meet the workplace
                  needs.Upon you becamequalified you will open the doors to the
                  best and most interesting roles all over the world, ACCA
                  Qualification is a long journey that requireachieving three
                  stages of exams, plus an Ethics and Professional Skills module
                  and a Professional Experience Requirement.
                  <br /> The pass mark for all ACCA Qualification examinations
                  is 50%. For more details visit ACCA website
                  <a
                    className="px-2"
                    href="https://www.accaglobal.com/"
                    target="blank"
                    style={{ color: "white" }}
                  >
                    https://www.accaglobal.com/
                  </a>
                </p>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="p-3">
                <h5 className="mb-1">OUR ROLE</h5>
                <p>
                  Our qualified professional tutors have the skills and
                  experience required to guide you through your ACCA
                  qualification journey and help you to accomplish the mission
                  and complete your journey to become one of ACCA family. In
                  addition to the deep knowledge, you will gain from the
                  program; our tutors will teach you the right technique and
                  time management to tackle your exams.
                </p>
              </div>
            </Col>
            <Col sm={5}></Col>
          </Row>
        </Container>

        <Container className="customContainer" style={{ overflow: "hidden" }}>
          <Row className="mt-2">
            <Col className="colCards text-left" sm="12" md="12">
              <h3 style={{ color: "#d8b671" }} className="">
                APPLIED SKILLS
              </h3>
              <Row className="mt-2 rowProgram">
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Performance Management (PM){" "}
                  </h5>
                  <p>
                    The aim of PM is to develop knowledge and skills in the
                    application of management accounting techniques to
                    quantitative and qualitative information for planning,
                    decision-making, performance evaluation, and control.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/pm">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>Taxation (TX-UK) </h5>
                  <p>
                    The aim of TX is to develop knowledge and skills relating to
                    the tax system as applicable to individuals, single
                    companies, and groups of companies.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/taxation">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Financial Reporting (FR){" "}
                  </h5>
                  <p>
                    The aim of FR is to develop knowledge and skills in
                    understanding and applying IFRS Standards and the
                    theoretical framework in the preparation of financial
                    statements of entities.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/FR">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
              </Row>
              <Row className="mt-4 rowProgram">
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Audit and Assurance (AA-INT)
                  </h5>
                  <p>
                    The aim of the AA is to develop the knowledge and skills
                    expected of anAuditor, Audit and Assurance syllabus is
                    essentially divided into five areas.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/AccaAudit">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Financial Management (FM){" "}
                  </h5>

                  <p>
                    The aim of the FM is to develop the knowledge and skills
                    expected of a finance manager, in relation to investment,
                    financing, and dividend policy decisions.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/fm">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col className="p-5" sm="12" md="4"></Col>
              </Row>{" "}
            </Col>
          </Row>
          {/****************************************************************************** */}

          <Row className="mt-2">
            <Col className="colCards text-left" sm="12" md="12">
              <h3 style={{ color: "#d8b671" }} className="">
                STRATEGIC PROFESSIONAL
              </h3>
              <Row className="mt-2 rowProgram">
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Strategic Business Leader (SBL)
                  </h5>
                  <p>
                    The aim of SBL is to demonstrate organizational leadership
                    and senior consultancy or advisory capabilities and relevant
                    professional skills, through the context of an integrated
                    case study.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/sbl">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Strategic Business Reporting (SBR-INT)
                  </h5>
                  <p>
                    The aim of SBR is to discuss, apply and evaluate the
                    concepts, principles and practices that underpin the
                    preparation and interpretation of corporate reports in
                    various contexts.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/SBR">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Advanced Financial Management (AFM){" "}
                  </h5>
                  <p>
                    The aim of AFM is to apply relevant knowledge, skills and
                    exercise professional judgement as expected of a senior
                    financial executive or advisor, in taking or recommending
                    decisions relating to the financial management of an
                    organisation.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/AFM">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
              </Row>
              <Row className="mt-4 rowProgram">
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Advanced Performance Management (APM)
                  </h5>
                  <p>
                    The aim of APM is to apply relevant knowledge, skills and
                    exercise professional judgement in selecting and applying
                    strategic management accounting techniques in different
                    business contexts.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/APM">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Advanced Taxation (ATX-UK){" "}
                  </h5>

                  <p>
                    The aim of ATX is to apply relevant knowledge and skills and
                    exercise professional judgement in providing relevant
                    information and advice to individuals and businesses on the
                    impact of the major taxes on financial decisions and
                    situations.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/ATX">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="4">
                  <h5 style={{ fontSize: "17px" }}>
                    Advanced Audit and Assurance{" "}
                  </h5>

                  <p>
                    The aim of AAA is to analyse, evaluate and conclude on the
                    assurance engagement and other audit and assurance issues in
                    the context of best practice and current developments.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/AAA">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
              </Row>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
