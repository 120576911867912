// Libraries
import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
// Styling
import "./App.css";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import About from "./components/pages/About";

// Components
import Home from "./components/pages/Home";
import ProgramDetails from "./components/pages/ProgramDetails";
import AccaPrograms from "./components/pages/AccaPrograms";
import IfrsPrograms from "./components/pages/IfrsPrograms";
import InterAccounting from "./components/pages/Tailored/InterAccounting";
import Auditing from "./components/pages/Tailored/Auditing";
import Finance from "./components/pages/Tailored/Finance";
import Cpd from "./components/pages/Cpd";
import AdvancedAccounting from "./components/pages/Tailored/AdvancedAccount";
import InterAuditing from "./components/pages/InterAuditing";
import AdvancedAuditing from "./components/pages/AdvancedAuditing";
import InterFinance from "./components/pages/InterFinance";
import AdvancedFinance from "./components/pages/AdvancedFinance";
import AccaMain from "./components/pages/AccaMain";
import Team from "./components/pages/Team";
import PM from "./components/pages/ACCA/PM";
import Taxation from "./components/pages/ACCA/Taxations";
import FR from "./components/pages/ACCA/FR";
import Audit from "./components/pages/ACCA/Audit";
import FM from "./components/pages/ACCA/FM";
import SBL from "./components/pages/ACCA/SBL";
import SBR from "./components/pages/ACCA/SBR";
import AFM from "./components/pages/ACCA/AFM";
import APM from "./components/pages/ACCA/APM";
import ATX from "./components/pages/ACCA/ATX";
import AAA from "./components/pages/ACCA/AAA";
import TailoredMain from "./components/pages/Tailored/TailoredMain";
import ArabicInterAccount from "./components/pages/Tailored/ArabicInterAccount";
import ArabicAdvancedAccount from "./components/pages/Tailored/ArabicAdvancedAccount";
import AuditAR from "./components/pages/Tailored/AuditAR";
import ARfinance from "./components/pages/Tailored/ARfinance";
import privacy from "./components/pages/privacy";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          {/* <Header /> */}
          <Route exact path="/" component={Home} />
          <Route exact path="/details" component={ProgramDetails} />
          <Route exact path="/about" component={About} />
          <Route exact path="/acca" component={AccaPrograms} />
          <Route exact path="/Ifrsprograms" component={IfrsPrograms} />
          
          <Route exact path="/auditing" component={Auditing} />
          <Route exact path="/finance" component={Finance} />
          <Route exact path="/cpdProgram" component={Cpd} />
          <Route exact path="/tailoredPrograms" component={TailoredMain} />
          <Route exact path="/ACCAprograms" component={AccaMain} />

          <Route
            exact
            path="/intermediateAccount"
            component={InterAccounting}
          />
          <Route
            exact
            path="/intermediateAccountAR"
            component={ArabicInterAccount}
          />
           <Route
            exact
            path="/advancedAccountAR"
            component={ArabicAdvancedAccount}
          />
            <Route
            exact
            path="/ARaudit"
            component={AuditAR}
          />
           <Route
            exact
            path="/policy"
            component={privacy}
          />
          <Route
            exact
            path="/financeAR"
            component={ARfinance}
          />
          <Route
            exact
            path="/advancedAccount"
            component={AdvancedAccounting}
          />
          <Route exact path="/intermediateAudit" component={InterAuditing} />
          <Route exact path="/advancedAudit" component={AdvancedAuditing} />
          <Route exact path="/IntermediateFinance" component={InterFinance} />
          <Route exact path="/AdvancedFinance" component={AdvancedFinance} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/pm" component={PM} />
          <Route exact path="/taxation" component={Taxation} />
          <Route exact path="/FR" component={FR} />
          <Route exact path="/AccaAudit" component={Audit} />
          <Route exact path="/fm" component={FM} />
          <Route exact path="/sbl" component={SBL} />
          <Route exact path="/SBR" component={SBR} />
          <Route exact path="/AFM" component={AFM} />
          <Route exact path="/APM" component={APM} />
          <Route exact path="/ATX" component={ATX} />
          <Route exact path="/AAA" component={AAA} />
          <Route exact path="/audit" component={Auditing} />

          <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;
