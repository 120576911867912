import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <div className="myFooter">
        <div className="mt-1 p-4">
          <Container className="footer">
            <Row>
              <Col sm="12" md="3">
                <p className="pf1">
                  Maadi, Cairo, Egypt
                  <br />
                  Marketing@oneacademy.info
                </p>
              </Col>
              <Col sm="12" md="3">
                <p className="pf2">
                  <i class="fas fa-phone-volume pr-2"></i> 01050553259
                </p>
                <p className="pf2">
                  <i class="fab fa-whatsapp pr-2"></i> 01050553249
                </p>
              </Col>
              <Col sm="12" md="3">
                <p className="pf3">Follow us on</p>
                <div>
                  <a href="https://www.facebook.com/One-Academy-107492174943077/" target="_blank">
                    <i class="fab fa-facebook-square px-3"></i>
                  </a>
                  {/* <a href="https://www.twitter.com/" target="_blank">
                    {" "}
                    <i class="fab fa-twitter-square px-3"></i>{" "}
                  </a> */}
                  <a href="https://www.linkedin.com/company/oneacademy-education" target="_blank">
                    {" "}
                    <i class="fab fa-linkedin px-3"></i>{" "}
                  </a>
                </div>
              </Col>
              <Col className='mt-3' sm="12" md="3">
              <p><Link style={{fontSize:'18px'}} to="/policy">Privacy Policy</Link></p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
