import React, { Component } from "react";
import Header from "../layout/Header";
import { Container, Row, Col, Image } from "react-bootstrap";
import team1 from "../../imgs/team1.jpeg";
import team3 from "../../imgs/team3.jpeg";
import team4 from "../../imgs/team4.jpeg";
import team5 from "../../imgs/team5.jpeg";
import team6 from "../../imgs/team6.jpeg";
import team7 from "../../imgs/team7.jpeg";
import team8 from "../../imgs/team8.jpeg";
import team9 from "../../imgs/team9.jpeg";

export default class Team extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Header />
        <div className="team"></div>

        <div className="mt-5">
          <Container className="p-3">
            <Row>
              <Col sm={12}>
                {" "}
                <h2>OUR TEAM</h2>
              </Col>
            </Row>
            <Row>
              <Col sm="12 p-3">
                <p style={{ color: "202641", fontSize: "18px" }}>
                  <strong>ONE ACADEMY</strong> has a talented team who is
                  responsible for delivering high quality of educational and
                  professional services to ensure success and adding value to
                  our candidates. Our team comprising of highly qualified
                  professionals and academic MSc.
                </p>
              </Col>
            </Row>
          </Container>{" "}
            <Container className="teamDiv">
              <Row>
                <Col className="mt-4" sm="12">
                  <p className="teamT1">Tarek El-Tonsy</p>
                  <p className="teamT1">FR, SBR & IFRS </p>
                </Col>
                <Col sm="12" md="4">
                  <div className="p-4">
                    <Image src={team1} className=" img-fluid pb-3" />
                  </div>
                </Col>
                <Col className="teamBio mt-5 pb-5" sm="12" md="7">
                  <p className="teamT2">Qualifications </p>
                  <p className="teamT3">
                    Association of Chartered Certified Accountants (ACCA)
                  </p>
                  <p className="teamT3">
                    Egyptian Society of Accountants and Auditors (ESAA)
                  </p>
                  <p className="teamT2">Experience </p>
                  <p className="teamT3">
                    Quality Assurance Reviewer at Financial Regulatory Authority
                    (FRA)
                  </p>
                  <p className="teamT3">Former Audit Manager at BDO Egypt</p>
                  <p className="teamT3">
                    More than15 years of experience in Audit & Assurance,
                    Financial Reporting, Financial Advisory and Quality Control
                    Review{" "}
                  </p>
                  {/* <hr className="lineee" /> */}
                  <p className="teamT2">Achievement and Rewards </p>
                  <p className="teamT3">
                    Advance Audit & Assurance (AAA) Prizewinner
                  </p>
                </Col>
              </Row>
            </Container>

            <Container className="teamDiv">
              <Row>
                <Col className="mt-4" sm="12">
                  <p className="teamT1">Hatem Sabry</p>
                  <p className="teamT1">TX & ATX</p>
                </Col>
                <Col sm="12" md="4">
                  <div className="imgDiv p-3">
                    <Image src={team4} className=" img-fluid pb-3" />
                  </div>
                </Col>
                <Col className="teamBio my-5" sm="12" md="7">
                  <p className="teamT2">Qualifications </p>
                  <p className="teamT3">
                    Association of Chartered Certified Accountants (ACCA)
                  </p>
                  <p className="teamT3">
                    Master’s Degree in Accounting from the University College
                    London, UK (MSc)
                  </p>
                  <p className="teamT2">Experience </p>
                  <p className="teamT3">
                    Regional Senior Manager Tax Operations Middle East, Turkey
                    and Africa (META) - General Electric Gas Power Former Senior
                    Manager Tax, International Tax, Business Advisory and
                    Accounting Processes Services with big four audit firms in
                    Egypt, UAE, Saudi and Qatar.
                  </p>
                  <p className="teamT3">
                    More than15 years of experience in Statutory and Tax
                    reporting, Tax Accounting and International Tax{" "}
                  </p>

                  {/* <hr className="lineee" /> */}
                  <p className="teamT2">Achievement and Rewards </p>
                  <p className="teamT3">
                    Accounting Expert with the International Court of
                    Arbitration of the International Chamber of Commerce (ICC)
                    in Paris.
                  </p>
                  <p className="teamT3">
                    Accounting Expert Witness with the American Institute of
                    Experts in Newyork
                  </p>
                </Col>
              </Row>
            </Container>

            <Container className="teamDiv">
              <Row>
                <Col className="mt-4" sm="12">
                  <p className="teamT1">Tarek Mostafa</p>
                  <p className="teamT1">AA & AAA </p>
                </Col>
                <Col sm="12" md="4">
                  <div className="p-4">
                    <Image src={team5} className=" img-fluid pb-3" />
                  </div>
                </Col>
                <Col className="teamBio my-5" sm="12" md="7">
                  <p className="teamT2">Qualifications </p>
                  <p className="teamT3">
                    Part qualified Association of Chartered Certified
                    Accountants (ACCA)
                  </p>
                  <p className="teamT3">
                    Certified Accounting Technician (CAT)
                  </p>
                  <p className="teamT2">Experience </p>
                  <p className="teamT3">
                    Assistant Manager, Assurance Services - EY
                  </p>
                  <p className="teamT3">
                    More than 10 years of experience in Audit & Assurance,
                    Financial Accounting Advisory Services at leading
                    professional services firms (EY, KPMG, and Grant Thornton)
                  </p>
                </Col>
              </Row>
            </Container>

            <Container className="teamDiv">
              <Row>
                <Col className="mt-4" sm="12">
                  <p className="teamT1">Ahmed Ghazy</p>
                  <p className="teamT1">Finance Instructor </p>
                </Col>
                <Col sm="12" md="4">
                  <div className="imgDiv p-2">
                    <Image src={team6} className=" img-fluid pb-3" />
                  </div>
                </Col>
                <Col className="teamBio my-5" sm="12" md="7">
                  <p className="teamT2">Qualifications </p>
                  <p className="teamT3">Certified Public Accountant (CPA)</p>
                  <p className="teamT3">
                    Certified Management Accountant (CMA)
                  </p>
                  <p className="teamT3">Certified Financial Analyst (CFI) </p>
                  <p className="teamT2">Experience </p>
                  <p className="teamT3">
                    Investment Analysis & Strategy Manager – Hayel Saeed Anam
                    (HAS)
                  </p>
                  <p className="teamT3">
                    Senior advisor to management of Haykala managers & Advisors,
                    KSA
                  </p>
                  <p className="teamT3">
                    Former Vice President – Manhattan Capital, KSA{" "}
                  </p>
                  <p className="teamT3">
                    Former Senior Assurance & Advisory (EY), MENA{" "}
                  </p>
                  <p className="teamT3">
                    More than10 years of experience in IPOs, Investment Banking,
                    Due Diligence, Corporate Finance, Private Equity and
                    Structuring M&A transactions with focus on business analysis
                    and valuation, Financial Structuring and transaction
                    underwriting
                  </p>
                  <p className="teamT2">Achievement and Rewards </p>
                  <p className="teamT3">
                    Recognition of top ten data analytics professionals in MENA
                    by EY
                  </p>
                </Col>
              </Row>
            </Container>

            <Container className="teamDiv">
              <Row>
                <Col className="mt-4" sm="12">
                  <p className="teamT1">Noha Ihab</p>
                  <p className="teamT1">FM & AFM </p>
                </Col>
                <Col sm="12" md="4">
                  <div className="imgDiv p-2">
                    <Image src={team8} className=" img-fluid pb-3" />
                  </div>
                </Col>
                <Col className="teamBio my-5" sm="12" md="7">
                  <p className="teamT2">Qualifications </p>
                  <p className="teamT3">
                    Master’s Degree in Investments from the University of
                    Birmingham, UK (MSc)
                  </p>
                  <p className="teamT3">
                    Certified Treasury Professional from Association for
                    Financial Professionals (CTP)
                  </p>

                  <p className="teamT2">Experience </p>
                  <p className="teamT3">
                    Lecturer of Finance at Cairo University and Misr
                    International University (MIU)
                  </p>
                  <p className="teamT3">
                    Investor relations, Senior Manager - Prime FinTech
                  </p>
                  <p className="teamT3">
                    Former deputy head for equity placements “Corporate finance
                    department” (FRA){" "}
                  </p>
                  <p className="teamT3">
                    More than 10 years of experience in Finance conducting
                    several IPOs, equity placements, mergers and acquisitions,
                    stock splits, business report, Islamic finance and crowd
                    funding and capital restructuring transactions{" "}
                  </p>

                  <p className="teamT2">Achievement and Rewards </p>
                  <p className="teamT3">
                    Founder and a volunteer at the Education Initiative of CFA
                    society in Egypt{" "}
                  </p>
                  <p className="teamT3">
                    International Future leader fellowship by the British
                    Council{" "}
                  </p>
                  <p className="teamT3">
                    Record of accomplishment in financial research{" "}
                  </p>
                  <p className="teamT3">
                    Engage with the British embassy as a Mentor at (She Leads)
                    program{" "}
                  </p>
                  <p className="teamT3">
                    Engage in formulating regulatory legislations for financial
                    valuation and equity placements standards{" "}
                  </p>
                </Col>
              </Row>
            </Container>
            <Container className="teamDiv">
              <Row>
                <Col className="mt-4" sm="12">
                  <p className="teamT1">Sally El-Shenawy</p>
                  <p className="teamT1">PM & APM </p>
                </Col>
                <Col sm="12" md="4">
                  <div className="p-4">
                    <Image src={team9} className=" img-fluid pb-3" />
                  </div>
                </Col>
                <Col className="teamBio my-5" sm="12" md="7">
                  <p className="teamT2">Qualifications </p>
                  <p className="teamT3">
                    Association of Chartered Certified Accountants (ACCA)
                  </p>
                  <p className="teamT3">
                    Certified Management Accountant (CMA)
                  </p>
                  <p className="teamT3">
                    Mini Master of Business Administration from Eton University,
                    UK (MBA)
                  </p>
                  <p className="teamT2">Experience </p>
                  <p className="teamT3">
                  Senior Management Accountant - Dreem Mashreq Foods S.A.E.{" "}
                </p>
                <p className="teamT3">
                  More than10 years of experience in Management Accounting
                  conducting Managerial Accounting, Budgeting, Financial
                  Analysis and Cost Control.
                </p>
                  <p className="teamT2">Achievement and Rewards </p>
                  <p className="teamT3">
                    Advanced Performance Management (APM) Prizewinner
                  </p>
                </Col>
              </Row>
            </Container>

            <Container className="teamDiv">
              <Row>
                <Col className="mt-4" sm="12">
                  <p className="teamT1">Khaled Fahem</p>
                  <p className="teamT1">Independent Director </p>
                </Col>
                <Col sm="12" md="4">
                  <div className="p-4">
                    <Image src={team7} className=" img-fluid pb-3" />
                  </div>
                </Col>
                <Col className="teamBio my-5" sm="12" md="7">
                  <p className="teamT2">Qualifications </p>
                  <p className="teamT3">
                    Association of Chartered Certified Accountants (ACCA)
                  </p>

                  <p className="teamT2">Experience </p>
                  <p className="teamT3">
                    Financial controller for several companies in UK
                  </p>
                  <p className="teamT3">Former external auditor at BDO Egypt</p>
                  <p className="teamT3">
                    More than10 years of experience in Audit & Assurance,
                    Financial Reporting, and managerial accounting{" "}
                  </p>
                  <p className="teamT2">Achievement and Rewards </p>
                  <p className="teamT3">
                    Advance Financial Management (AFM) Prizewinner
                  </p>
                </Col>
              </Row>
            </Container>

           
           
        </div>
      </div>
    );
  }
}
