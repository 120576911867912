import React, { Component } from "react";
import Header from "../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
export default class Cpd extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="cpdBg">
        <Container>
            <Row>
              <Col sm={5}></Col>
              <Col className="mainTitle" sm={7}>
                <h1>The</h1>
                <h1>ACADEMY</h1>
                <h1>CPD</h1>
                <h1>PROGRAM</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="my-5">
          <Row>
            <Col sm={12}>
              {" "}
              <h2>CONTINUING PROFESSIONAL DEVELOPMENT (CPD)</h2>
            </Col>
            <Col
              style={{
                textAlign: "justify",
                fontSize: "20px",
                marginTop: "20px",
              }}
              sm={12}
            >
              <p>
                CPD gives you access updated professional and technical contents
                that ensure both your knowledge and skills are maintained up to
                date, so you remain professionally competent.
              </p>
            </Col>
          </Row>
          <Row className="">
            <Col className="py-5" sm={12}>
              <p style={{ fontSize: "18px" }}>
                We offer Continuing Professional Development (CPD) training for
                accountants and auditors. We offer a range of tailored programs
                based on the clients' needs. Our qualified professional
                instructors will be delighted to share their experience with
                your staff and guide them to maintain professional skills and
                updating their knowledge in accounting and auditing standards in
                addition to the guidance of application in workplace.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
