import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../layout/Header";

export default function privacy() {
  return (
      <>
      <Header />
    <div
      style={{
        backgroundColor: "#202641",
        height: "100vh",
        color: "#d8b671",
        textAlign: "left",
        marginTop:'50px'
      }}
    >
      <Container>
        <Row>
          <Col sm="12" className="text-left mt-5">
            <h2>One Academy Privacy and Cookies Policy</h2>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-left mt-5">
            <p style={{ textAlign: "left" }}>
              We respects the privacy of our users. This Privacy Policy explains
              how we collect, use, disclose, and safeguard your information when
              you visit our website, including any other media form, media
              channel, mobile website, or mobile application related . Please
              read this privacy policy carefully. If you do not agree with the
              terms of this privacy policy, please do not access the site. We
              reserve the right to make changes to this Privacy Policy at any
              time and for any reason. We will alert you about any changes by
              updating the “Last Updated” date of this Privacy Policy. Any
              changes or modifications will be effective immediately upon
              posting the updated Privacy Policy on the Site, and you waive the
              right to receive specific notice of each such change or
              modification. You are encouraged to periodically review this
              Privacy Policy to stay informed of updates. You will be deemed to
              have been made aware of, will be subject to, and will be deemed to
              have accepted the changes in any revised Privacy Policy by your
              continued use of the Site after the date such revised Privacy
              Policy is posted.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-left mt-3">
            <h4 style={{ textAlign: "left" }}>Contents</h4>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-left mt-3">
            <h6 style={{ textAlign: "left" }}>
              COLLECTION OF YOUR INFORMATION
            </h6>
            <h6 style={{ textAlign: "left" }}>
              We may collect information about you in a variety of ways. The
              information we may collect on the Site includes:
            </h6>
            <p style={{ textAlign: "left" }}>
              Personal Data Personally identifiable information, such as your
              name, shipping address, email address, and telephone number, and
              demographic information, such as your age, gender, hometown, and
              interests, that you voluntarily give to us participate in various
              activities related to the Site. You are under no obligation to
              provide us with personal information of any kind, however your
              refusal to do so may prevent you from using certain features of
              the Site.
            </p>
            <h6 style={{ textAlign: "left" }}> Derivative Data</h6>
            <p style={{ textAlign: "left" }}>
              Information our servers automatically collect when you access the
              Site, your browser type, your operating system, your access times,
              and the pages you have viewed directly before and after accessing
              the Site.
            </p>
            <h6 style={{ textAlign: "left" }}>Cookies</h6>
            <p style={{ textAlign: "left" }}>
              We may use cookies, and other tracking technologies on the Site
              (and our mobile application) to help customize the Site (and our
              mobile application) and improve your experience.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}
