import React, { Component } from "react";
import Header from "../../layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
export default class TailoredMain extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <Header />
        {/* <div className='pt-5 mt-5'>
        <h2 className="mb-4">ACCA QUALIFICATION</h2>
        </div> */}
        <div className="accaBG" style={{ marginTop: "50px" }}></div>

        <Container className="homeAbout mt-5">
          <Row>
            <Col sm={12} md={6}>
              <div className="p-3">
                <h5 className="mb-2">ABOUT TAILORED PROGRAMS</h5>
                <p>
                  Expert team of ONE ACADEMY has developed tailored programs to
                  gives you the knowledge and skills required to become a
                  professional in your career and to meet the workplace needs.
                  Upon completion of the tailored program you will increase
                  opportunities to join the job you have always dream. To get
                  the most benefits from our tailored programs you are
                  recommended to apply for both intermediate and advanced levels
                  in selected major.
                  <br />
                  Upon the completion of the program you will be assessed by an
                  exam to evaluate the progress and you will be accredited
                  certificate attached with your exam result.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="p-3">
                <h5 className="mb-2">OUR ROLE</h5>
                <p>
                  Our qualified professional tutors have the skills and
                  experience required to give you deep knowledge and skills
                  required to become a professional.
                </p>
              </div>
            </Col>
            <Col sm={5}></Col>
          </Row>
        </Container>

        <Container className="customContainer" style={{ overflow: "hidden" }}>
          <Row className="mt-2">
            <Col className="colCards text-left" sm="12" md="12">
              <h3 style={{ color: "#d8b671" }} className=" mb-5">
                ENGLISH PROGRAMS{" "}
              </h3>
              <Row className="mt-2 rowProgram">
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="5">
                  <h5 style={{ fontSize: "17px" }}>INTERMEDIATE ACCOUNTING </h5>
                  <p>
                    The aim of Intermediate Accounting is to develop knowledge
                    and skills in understanding and applying basic Egyptian
                    Accounting Standards in recognition and measurement of the
                    transactions and the preparation of financial statements of
                    entities, including disclosures required.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/intermediateAccount">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col sm="12" md="2"></Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="5">
                  <h5 style={{ fontSize: "17px" }}>ADVANCED ACCOUNTING </h5>
                  <p>
                    The aim of Advanced Accounting is to develop knowledge and
                    skills in understanding and applying complicated Egyptian
                    Accounting Standards in recognition and measurement of the
                    transactions and the preparation of financial statements of
                    entities, including groups and disclosures required.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/advancedAccount">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
              </Row>
              <Row className="mt-4 rowProgram">
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="5">
                  <h5 style={{ fontSize: "17px" }}>Audit and Assurance</h5>
                  <p>
                    The aim of Audit and Assurance is to develop knowledge and
                    skills for the auditors in understanding and applying When
                    performing the audit process for the audit client, the
                    program includes explanation and practical application of
                    how to perform the audit process.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/auditing">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col sm="12" md="2"></Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="5">
                  <h5 style={{ fontSize: "17px" }}>FINANCE </h5>
                  <p>
                    The aim of the Finance is to develop the knowledge and
                    skills expected of a finance manager, in relation to perform
                    business valuation for entities, working capital management,
                    investment appraisal, financing decisions, dividend policy
                    decisions and risk management.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/finance">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
              </Row>{" "}
            </Col>
          </Row>
        </Container>

        <Container className="customContainer" style={{ overflow: "hidden" }}>
          <Row className="mt-1">
            <Col className="colCards text-left" sm="12" md="12">
              <h3 style={{ color: "#d8b671" }} className=" mb-5">
                ARABIC PROGRAMS{" "}
              </h3>
              <Row className="mt-2 rowProgram">
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="5">
                  <h5 style={{ fontSize: "17px" }}>المحاسبة المتوسطة </h5>
                  <p dir="rtl">
                    الهدف من المحاسبة المتوسطة هو تطوير المعرفة والمهارات في فهم
                    وتطبيق معايير المحاسبة المصرية الأساسية اللازمة للاعتراف
                    وقياس المعاملات المحاسبية وإعداد التقارير المالية للشركات،
                    بما في ذلك الإفصاحات المطلوبة.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/intermediateAccountAR">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col sm="12" md="2"></Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="5">
                  <h5 style={{ fontSize: "17px" }}> المحاسبة النهائيه </h5>
                  <p dir="rtl">
                    الهدف من المحاسبة النهائيه هو تطوير المعرفة والمهارات في فهم
                    وتطبيق معايير المحاسبة المصرية المعقدة اللازمة للاعتراف
                    وقياس المعاملات المحاسبية وإعداد القوائم المالية والقوائم
                    المجمعة للشركات الكبرى، بما في ذلك الإفصاحات المطلوبة.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/advancedAccountAR">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
              </Row>
              <Row className="mt-4 rowProgram">
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="5">
                  <h5 style={{ fontSize: "17px" }}>المراجعة وخدمات التأكد</h5>
                  <p dir="rtl">
                    الهدف من برنامج المراجعة وخدمات التأكد هو تطوير المعرفة
                    والمهارات لدى مراجع الحسابات في فهم وتطبيق المعايير المصرية
                    للمراجعة عند القيام بأداء عملية المراجعة للعميل، حيث يثضمن
                    البرنامج الشرح والتطبيق العملى لكيفية اداء عملية المراجعة.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/ARaudit">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
                <Col sm="12" md="2"></Col>
                <Col className="programBox px-5 pt-4 pb-5 mb-3" sm="12" md="5">
                  <h5 style={{ fontSize: "17px" }}>التمويل </h5>
                  <p dir="rtl">
                    الهدف من التمويل هو تطوير المعرفة والمهارات المتوقعة من
                    المدير المالى، فيما يتعلق بأداء تقييم الأعمال للشركات ،
                    وإدارة رأس المال العامل، وتقييم الاستثمار، واتخاذ قرارات
                    التمويل المناسبة، ووضع سياسة توزيع الأرباح وكذلك وإدارة
                    المخاطر.
                  </p>
                  <p className="check">
                    {" "}
                    <Link to="/financeAR">
                      Checkout Program
                      <i className="fas fa-arrow-right px-2"></i>
                    </Link>
                  </p>
                </Col>
              </Row>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
